<template>
  <div>
    <c-table
      ref="tableAttend"
      title="훈련참가자 목록"
      :columns="grid.columns"
      :data="traning.emergencyTrainingUsersModels"
      selection="multiple"
      :editable="editable && !disabled"
      :gridHeight="grid.height"
      :hideBottom="false"
      :isExcelDown="false"
      :usePaging="false"
      rowKey="attendUserId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem" />
          <c-btn v-if="editable && !disabled && traning.emergencyTrainingUsersModels.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeItem" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'traning-person',
  props: {
    traning: {
      type: Object,
      default: () => ({
        emergencyTrainingUsersModels: [],
        sopEmergencyTrainingId: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '460px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      this.$comm.getComboItems('EAP_TRAINING_USER_CLASS_CD', false).then(_result => {
        this.grid.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:350px',
            sortable: false,
          },
          {
            name: 'attendUserName',
            field: 'attendUserName',
            label: 'LBLNAME',
            align: 'center',
            style: 'width:350px',
            type: 'user',
            userId: 'attendUserId',
            sortable: false,
          },
          {
            setHeader: true,
            name: 'eapAttendTypeCd',
            field: 'eapAttendTypeCd',
            label: '구분',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            type: 'select',
            comboItems: _result
          },
          {
            setHeader: true,
            name: 'completeFlag',
            field: 'completeFlag',
            label: '이수여부',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'nonattendanceRemark',
            field: 'nonattendanceRemark',
            label: '비고(불참사유)',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'text'
          },
        ]
      });
    },
    addItem() {
      this.popupOptions.title = "훈련참가자 검색"; // 훈련참가자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.traning.emergencyTrainingUsersModels, {
            attendUserId: _item.userId,
          });
          if (index === -1) {
            this.traning.emergencyTrainingUsersModels.splice(0, 0, {
              sopEmergencyTrainingId: this.traning.sopEmergencyTrainingId,
              attendUserId: _item.userId,
              attendUserName: _item.userName,
              deptName: _item.deptName,
              eapAttendTypeCd: null,
              completeFlag: 'N',
              nonattendanceRemark: '',
            })
          }
        })
      }
    },
    removeItem() {
      let selectData = this.$refs['tableAttend'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.traning.emergencyTrainingUsersModels = this.$_.reject(this.traning.emergencyTrainingUsersModels, item)
        })
      }
    },
  }
};
</script>