var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "tableAttend",
          attrs: {
            title: "훈련참가자 목록",
            columns: _vm.grid.columns,
            data: _vm.traning.emergencyTrainingUsersModels,
            selection: "multiple",
            editable: _vm.editable && !_vm.disabled,
            gridHeight: _vm.grid.height,
            hideBottom: false,
            isExcelDown: false,
            usePaging: false,
            rowKey: "attendUserId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBLADD",
                          showLoading: false,
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addItem },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.traning.emergencyTrainingUsersModels.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          label: "LBLREMOVE",
                          showLoading: false,
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeItem },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }